import React from 'react';
import {DefaultState} from '../../common/reducer/reducers';
import OnboardingInfo from '../../onboarding/component/OnboardingInfo';
import ParentOverview from './ParentOverview';
import {useSelector} from 'react-redux';

function Dashboard() {
    const account = useSelector((state: DefaultState) => state.user.account);

    if (account?.children?.length) {
        return <ParentOverview/>;
    } else {
        return <OnboardingInfo/>;
    }
}

export default Dashboard;
