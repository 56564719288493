import React from 'react';

interface Props {
    size?: number;
}

const ErrorIconFilled = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" fill="currentColor"
                  d="M22.0016 12.0006C22.0016 6.47687 17.5238 1.99902 12.0001 1.99902C6.47638 1.99902 1.99854 6.47687 1.99854 12.0006C1.99854 17.5243 6.47638 22.0021 12.0001 22.0021C17.5238 22.0021 22.0016 17.5243 22.0016 12.0006ZM15.3588 8.64123C15.6517 8.93412 15.6517 9.40899 15.3588 9.70189L13.0607 12L15.3588 14.2981C15.6517 14.591 15.6517 15.0658 15.3588 15.3587C15.0659 15.6516 14.591 15.6516 14.2981 15.3587L12 13.0606L9.7019 15.3587C9.40901 15.6516 8.93414 15.6516 8.64124 15.3587C8.34835 15.0658 8.34835 14.591 8.64124 14.2981L10.9393 12L8.64124 9.70189C8.34835 9.40899 8.34835 8.93412 8.64124 8.64123C8.93414 8.34833 9.40901 8.34834 9.7019 8.64123L12 10.9393L14.2981 8.64123C14.591 8.34833 15.0659 8.34834 15.3588 8.64123Z"/>
        </svg>
    );
};

export default ErrorIconFilled;
