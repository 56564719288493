import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ChildSubscription} from '../../types/child/ChildSubscription';
import CheckIconFilled from '../icons/CheckIconFilled';
import Icon from '../icons/Icon';
import {DefaultState} from '../reducer/reducers';
import {formatDate} from '../util/DateUtil';
import Tooltip from './Tooltip';
import Week from './Week';

type Props = {
    subscription: ChildSubscription
}

function getSubscriptionState(subscription: ChildSubscription): string {
    const now = new Date();
    if (new Date(subscription.startDate) > now) {
        return 'FUTURE';
    }

    if (subscription.endDate && new Date(subscription.endDate) < now) {
        return 'EXPIRED';
    }

    if (subscription.pauseStartDate && new Date(subscription.pauseStartDate) <= now && new Date(subscription.pauseEndDate!) >= now) {
        return 'PAUSED';
    }

    return 'ACTIVE';
}

function getClassForSubscriptionState(state: string): string {
    switch (state) {
        case 'ACTIVE':
            return 'text-success';
        case 'EXPIRED':
            return 'text-muted';
        default:
            return 'font-weight-bold';
    }
}

function ChildSubscriptionState({subscription}: Readonly<Props>) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const catererChildBusinessDays = useSelector((state: DefaultState) => state.caterer.timeManagementChild?.businessDays);

    const subscriptionState = useMemo(() => getSubscriptionState(subscription), [subscription]);

    const [tooltipRoot, setTooltipRoot] = useState<HTMLSpanElement | null>(null);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    function formatSubscriptionDates(): string {
        let result = '';

        if (subscription.endDate) {
            result += formatDate(currentLanguage, subscription.startDate) + ' - ' + formatDate(currentLanguage, subscription.endDate);
        } else {
            result += t('Common.START') + ' ' + formatDate(currentLanguage, subscription.startDate);
        }

        if (subscription.pauseStartDate) {
            result += ` (${t('Subscription.PAUSE')}: ${formatDate(currentLanguage, subscription.pauseStartDate)} - ${formatDate(currentLanguage, subscription.pauseEndDate)})`;
        }

        return result;
    }

    return <div className="d-inline-flex align-items-start" onMouseEnter={() => setIsTooltipVisible(true)} onMouseLeave={() => setIsTooltipVisible(false)}>
        <div className="d-inline-flex">
            <span ref={setTooltipRoot} className={getClassForSubscriptionState(subscriptionState)}>{t('Subscription.STATE.' + subscriptionState)}</span>
            {
                subscriptionState === 'ACTIVE' &&
                <Icon src={<CheckIconFilled/>} className="icon-success-not-clickable ml-2 mr-4"/>
            }
        </div>
        {
            subscriptionState === 'ACTIVE' &&
            <Week availableDays={catererChildBusinessDays} selectedDays={subscription.weekdays}  small={true} onSelectWeekday={undefined}
                  onUpdateWeekdayArray={undefined} errorMessage={undefined} isValid={true}/>
        }
        <Tooltip isVisible={isTooltipVisible} root={tooltipRoot} placement="top">
            <span>{formatSubscriptionDates()}</span>
        </Tooltip>
    </div>;
}

export default ChildSubscriptionState;
