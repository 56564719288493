import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import accountApi from '../../account/api/accountApi';
import transactionApi from '../../administration/api/transactionApi';
import AccountDeleteCard from './AccountDeleteCard';
import AccountDeletionUserInputModal from './AccountDeletionUserInputModal';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import {logOut} from '../../common/util/UserSessionUtils';
import {showError} from '../../message/action/messageActions';
import {DeletionCheckResult} from '../types/DeletionCheckResult';

type Props = {
    accountId: number,
    deletionWorkflowType: 'PARENT' | 'SUPPORT'
}

function ParentAccountDeletion({accountId, deletionWorkflowType}: Readonly<Props>) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isAccountDeletable, setIsAccountDeletable] = useState(false);
    const [deletionCheckResult, setDeletionCheckResult] = useState<DeletionCheckResult>();
    const [balancePlusCancelableOrders, setBalancePlusCancelableOrders] = useState<number>();
    const [showConfirmationForSupport, setShowConfirmationForSupport] = useState(false);

    useMount(() => {
        // Check if the account is deletable in the respective workflow:
        accountApi.checkDeletable(accountId, true).then(response => {
            if (response.data.success) {
                const checkResult = response.data.result as DeletionCheckResult;
                setIsAccountDeletable(deletionWorkflowType === 'PARENT' ?
                    checkResult.deletable :
                    checkResult.deletable && !checkResult.needsWithdrawal && !checkResult.hasPendingWithdrawal);
            }
        });
    });

    function handleDeletionRequested() {
        if (deletionWorkflowType === 'PARENT') {
            // Check if anything has to be done before deleting the account (PARENT workflow only):
            accountApi.checkDeletable(accountId, false).then(response => {
                if (response.data.success) {
                    setIsAccountDeletable(response.data.result.deletable);
                    setDeletionCheckResult(response.data.result);

                    if (response.data.result.needsWithdrawal) {
                        transactionApi.findBalancePlusCancelableOrders().then(res => {
                            if (res.data.success) {
                                setBalancePlusCancelableOrders(res.data.result);
                            }
                        });
                    }
                }
            });
        } else {
            setShowConfirmationForSupport(true);
        }
    }

    function chooseConfirmationDialogBodyText() {
        if (!deletionCheckResult) {
            return '';
        }

        if (deletionCheckResult.hasPendingWithdrawal) {
            return t('User.DELETION.WITHDRAWAL_SUFFICIENT');
        } else {
            return t('User.DELETION.NO_LEFTOVER_CREDIT');
        }
    }

    function handleConfirmDeletionPreparation(withdrawalRequest: any) {
        setDeletionCheckResult(undefined);
        accountApi.prepareDeletion(accountId, withdrawalRequest).then(res => {
            if (res.data.success) {
                logoutGoodbye();
            } else {
                dispatch(showError('Error.GENERAL'));
            }
        }).catch(error => {
            dispatch(showError('Error.GENERAL', error));
        });
    }

    function handleConfirmDeleteAccount() {
        setDeletionCheckResult(undefined);
        accountApi.purge(accountId).then(res => {
            if (res.data.success) {
                logoutGoodbye();
            } else {
                dispatch(showError('Error.GENERAL'));
            }
        }).catch(error => {
            dispatch(showError('Error.GENERAL', error));
        });
    }

    function logoutGoodbye() {
        logOut(dispatch, () => history.push('/goodbye'));
    }

    return <>
        <AccountDeleteCard isAccountDeletable={isAccountDeletable} onClick={handleDeletionRequested} isParent={deletionWorkflowType === 'PARENT'}/>

        {/* No further user input needed: Delete right away or mark for deletion: */}
        {
            ((deletionCheckResult && !deletionCheckResult.needsWithdrawal) || showConfirmationForSupport) &&
            <ConfirmationDialog
                open={true}
                title={t('Account.CONFIRM_DELETE_TEXT')}
                body={chooseConfirmationDialogBodyText()}
                confirmLabel={t('Button.DELETE')}
                onConfirm={() => {
                    if (deletionCheckResult?.hasPendingWithdrawal) {
                        handleConfirmDeletionPreparation({});
                    } else {
                        handleConfirmDeleteAccount();
                    }
                }}
                onCancel={() => { setDeletionCheckResult(undefined); setShowConfirmationForSupport(false); }}
            />
        }

        {/* Let the user choose a Stripe payment method or enter bank account details: */}
        {
            deletionCheckResult && balancePlusCancelableOrders &&
            <AccountDeletionUserInputModal
                visible={deletionCheckResult.needsWithdrawal}
                deletionCheckResult={deletionCheckResult}
                withdrawalAmount={balancePlusCancelableOrders}
                onConfirm={deletionCheckResult.stripeRefundPaymentMethods.length > 0 && !deletionCheckResult.hasPendingWithdrawal ?
                    handleConfirmDeleteAccount : handleConfirmDeletionPreparation}
                onCancel={() => setDeletionCheckResult(undefined)}
            />
        }
    </>;
}

export default ParentAccountDeletion;
