import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import accountApi from '../../account/api/accountApi';
import {markChanges, unmarkChanges} from '../../common/action/pageLeaveActions';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import PageLeaveGuard from '../../common/component/PageLeaveGuard';
import Role from '../../common/enums/Role';
import {DefaultState} from '../../common/reducer/reducers';
import {checkValidation} from '../../common/util/ValidationUtil';
import {changeLanguage, SupportedLanguages} from '../../i18n/action/i18nActions';
import {showMessage} from '../../message/action/messageActions';
import {initialAccountValidation, validateAccount} from '../../types/account/AccountValidationUtil';
import {AccountType} from '../../types/AccountType';
import {Address} from '../../types/Address';
import {updateAccount} from '../../user/action/userActions';
import ParentProfileViewOptions from '../enum/ParentProfileViewOptions';
import ParentProfileChangePassword from './ParentProfileChangePassword';
import ParentProfileEditBase from './ParentProfileEditBase';
import ParentProfileOverview from './ParentProfileOverview';
import '../style/ParentProfile.scss';

function ParentProfileWrapper() {
    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const accountBeforeEdit = useSelector((state: DefaultState) => state.user.account);

    const [account, setAccount] = useState<Partial<AccountType> | undefined>(JSON.parse(JSON.stringify(accountBeforeEdit)));
    const [accountValidation, setAccountValidation] = useState(initialAccountValidation);
    const [isAccountValidated, setIsAccountValidated] = useState(false);
    const [activeElement, setActiveElement] = useState(ParentProfileViewOptions.PROFILE_OVERVIEW);
    const hasChanges = useSelector((state: DefaultState) => state.pageLeave.hasUnsavedChanges);
    const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

    function determineActiveElement() {
        switch (activeElement) {
            case ParentProfileViewOptions.PARENT_BASEDATA_EDIT:
                return <ParentProfileEditBase
                    account={account!}
                    onUpdateAccount={handleAccountChange}
                    onUpdateAccountAddress={handleAddressChange}
                    accountValidation={accountValidation}
                    onSave={() => saveAccount(false)}
                    onCancel={handleCancelEdit}
                />;
            case ParentProfileViewOptions.CHANGE_PASSWORD:
                return <ParentProfileChangePassword
                    account={account!}
                    onUpdateAccount={handleAccountChange}
                    accountValidation={accountValidation}
                    onSave={() => saveAccount(true)}
                    onCancel={handleCancelEdit}
                />;
            default:
                return <ParentProfileOverview
                    account={account!}
                    onShowEditParent={setActiveElement}
                    onGotoChildSetup={() => history.push('/children/new')}
                    onGotoEditChild={(childId: number) => history.push('/children/' + childId)}
                />;
        }
    }

    function saveAccount(withPassword: boolean) {
        if (validate(withPassword)) {
            let newLanguage: SupportedLanguages | undefined = undefined;
            if (account && account.language !== accountBeforeEdit?.language) {
                newLanguage = account.language
            }

            const accountToSave = {
                ...account,
                login: {
                    ...account!.login,
                    role: Role.PARENT
                }
            };

            accountApi.createOrUpdate(accountToSave).then(response => {
                if (response.data.success) {
                    dispatch(updateAccount(response.data.result));
                    dispatch(showMessage('Common.SAVE_SUCCESS'));
                    dispatch(unmarkChanges());
                    resetActiveElement();
                    if (newLanguage) {
                        dispatch(changeLanguage(newLanguage));
                    }
                } else {
                    // TODO-ak
                }
            });
        }
    }

    const validate = useCallback((withPassword: boolean) => {
        if (!account) {
            return false;
        }

        const newValidation = validateAccount(account, !withPassword);
        setIsAccountValidated(true);
        setAccountValidation(newValidation);

        return checkValidation(newValidation);
    }, [account]);

    useEffect(() => {
        if (isAccountValidated) {
            validate(false);
        }
    }, [account?.person, isAccountValidated, validate]);

    useEffect(() => {
        if (isAccountValidated) {
            validate(true);
        }
    }, [account?.login, isAccountValidated, validate]);

    function handleCancelEdit() {
        resetAccountData();
        resetActiveElement();
    }

    function resetActiveElement() {
        setActiveElement(ParentProfileViewOptions.PROFILE_OVERVIEW);
    }

    function resetAccountData() {
        dispatch(unmarkChanges());
        setAccount(JSON.parse(JSON.stringify(accountBeforeEdit)));
    }

    // XXX Kopiert aus Support-Bereich
    function handleAccountChange(key: string, value: string, subObject?: string) {
        dispatch(markChanges());
        setAccount(currentValue => {
            const newValue: any = { ...currentValue };

            if (subObject && newValue.hasOwnProperty(subObject)) {
                newValue[subObject][key] = value;
            } else if (subObject) {
                newValue[subObject] = {[key]: value};
            } else {
                newValue[key] = value;
            }

            return newValue;
        });
    }

    function handleAddressChange(newAddress: Address){
        dispatch(markChanges());
        setAccount((currentValue) => {
            return {
                ...currentValue,
                address: newAddress
            }
        });
    }

    if (!account?.person || !account.login) {
        return null;
    }

    return <>
        {determineActiveElement()}

        {
            // PageLeaveGuard is only triggered by change of uri path; the edit masks don't have separate paths
            // -> use this confirmation dialog when the back link is clicked
            showLeaveConfirmation &&
            <ConfirmationDialog
                open={showLeaveConfirmation}
                title={t('Common.UNSAVED_CHANGES_TITLE')}
                body={t('Common.UNSAVED_CHANGES_MESSAGE')}
                onConfirm={() => { setShowLeaveConfirmation(false); handleCancelEdit(); }}
                onCancel={() => setShowLeaveConfirmation(false)}
                confirmLabel={t('Common.UNSAVED_CHANGES_DISCARD')}
                cancelLabel={t('Common.UNSAVED_CHANGES_GO_BACK')}
            />
        }
        <PageLeaveGuard
            hasChanges={hasChanges}
        />
    </>;
}

export default ParentProfileWrapper;
