import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {hideLoader, showLoader, setLoaderHint} from '../action/LoaderAction';
import ArrowBackIcon from '../icons/ArrowBackIcon';
import ArrowHookUpLeftIcon from '../icons/ArrowHookUpLeftIcon';
import ArrowNextIcon from '../icons/ArrowNextIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import ArrowSortDownIcon from '../icons/ArrowSortDownIcon';
import ArrowSortUpIcon from '../icons/ArrowSortUpIcon';
import BillIcon from '../icons/BillIcon';
import BurgerIcon from '../icons/BurgerIcon';
import CalendarCancelIcon from '../icons/CalendarCancelIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CardIcon from '../icons/CardIcon';
import CashIcon from '../icons/CashIcon';
import ChatHelpIcon from '../icons/ChatHelpIcon';
import CheckIcon from '../icons/CheckIcon';
import CheckIconFilled from '../icons/CheckIconFilled';
import ClipboardTaskIcon from '../icons/ClipboardTaskIcon';
import ClockIcon from '../icons/ClockIcon';
import CloseIcon from '../icons/CloseIcon';
import CopyIcon from '../icons/CopyIcon';
import DeleteIcon from '../icons/DeleteIcon';
import DocumentIcon from '../icons/Document';
import DownloadIcon from '../icons/DownloadIcon';
import EditIcon from '../icons/EditIcon';
import ErrorIconFilled from '../icons/ErrorIconFilled';
import FoodIcon from '../icons/FoodIcon';
import HistoryIcon from '../icons/HistoryIcon';
import HomeIcon from '../icons/HomeIcon';
import ImageIcon from '../icons/ImageIcon';
import InfoIcon from '../icons/InfoIcon';
import KeyIcon from '../icons/KeyIcon';
import LeftIcon from '../icons/LeftIcon';
import LoginIcon from '../icons/LoginIcon';
import LogoutIcon from '../icons/LogoutIcon';
import MailIcon from '../icons/MailIcon';
import MinusIcon from '../icons/MinusIcon';
import MoneyIcon from '../icons/MoneyIcon';
import MultiplePersonIcon from '../icons/MultiplePersonIcon';
import PayoutIcon from '../icons/PayoutIcon';
import PersonIcon from '../icons/PersonIcon';
import PlusIcon from '../icons/PlusIcon';
import PreviewIcon from '../icons/PreviewIcon';
import PrintIcon from '../icons/PrintIcon';
import ProhibitedIcon from '../icons/ProhibitedIcon';
import RefreshIcon from '../icons/RefreshIcon';
import RightIcon from '../icons/RightIcon';
import SearchIcon from '../icons/SearchIcon';
import SendIcon from '../icons/SendIcon';
import ServingIcon from '../icons/ServingIcon';
import SettingIcon from '../icons/SettingIcon';
import SignatureIcon from '../icons/SignatureIcon';
import SpeechBubbleIcon from '../icons/SpeechBubbleIcon';
import StarIconEmpty from '../icons/StarIconEmpty';
import StarIconFilled from '../icons/StarIconFilled';
import TextBulletListIcon from '../icons/TextBulletListIcon';
import ToolIcon from '../icons/ToolIcon';
import UploadIcon from '../icons/UploadIcon';
import WalletCreditCardIcon from '../icons/WalletCreditCardIcon';
import WarningIcon from '../icons/WarningIcon';
import WrenchIcon from '../icons/WrenchIcon';
import ConfirmCard from './ConfirmCard';
import Checkbox from './form/Checkbox';
import LoginCredentials from './form/LoginCredentials';
import SlideDownCard from './SlideDownCard';

function PlaygroundArea() {
    const dispatch = useDispatch();

    const [emailAddress, setEmailAddress] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordRepeat, setPasswordRepeat] = useState<string>();
    const [submittedEmailAddress, setSubmittedEmailAddress] = useState<string>();
    const [submittedPassword, setSubmittedPassword] = useState<string>();
    const [submittedPasswordRepeat, setSubmittedPasswordRepeat] = useState<string>();
    const [isIconsCardClosed, setIsIconsCardClosed] = useState(true);

    const [showEmailAddress, setShowEmailAddress] = useState<boolean | null>(true);
    const [showPassword, setShowPassword] = useState<boolean | null>(true);
    const [isEmailReadonly, setIsEmailReadonly] = useState<boolean | null>(false);
    const [isPasswordReadonly, setIsPasswordReadonly] = useState<boolean | null>(false);

    const handleChange = useCallback((key: string, value: string) => {
        switch (key) {
            case 'emailAddress':
                setEmailAddress(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'passwordRepeat':
                setPasswordRepeat(value);
                break;
        }
    }, []);

    const handleClick = useCallback((event) => {
        event.preventDefault();
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    const handleClickForLongAction = useCallback((event) => {
        event.preventDefault();
        dispatch(setLoaderHint());
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    const submitCredentials = useCallback(() => {
        setSubmittedEmailAddress(emailAddress);
        setSubmittedPassword(password);
        setSubmittedPasswordRepeat(passwordRepeat);
    }, [emailAddress, password, passwordRepeat]);

    const resetCredentials = useCallback(() => {
        setSubmittedEmailAddress(undefined);
        setSubmittedPassword(undefined);
        setSubmittedPasswordRepeat(undefined);
    }, []);

    return (
        <div className="container">
            This is the playground area. Have fun!
            <div className="mt-3 mb-4">
                <button className="btn btn-primary" onClick={handleClick}>Loader!</button>
                <button className="btn btn-secondary ml-2" onClick={handleClickForLongAction}>Trigger long action</button>
            </div>

            <LoginCredentials
                login={{emailAddress, password, passwordRepeat}}
                onChange={handleChange}
                validation={{catererId: true, emailAddress: true, password: true, passwordRepeat: true}}
                onSubmit={submitCredentials}
                showEmail={showEmailAddress ?? undefined}
                showPassword={showPassword ?? undefined}
                emailReadOnly={isEmailReadonly ?? undefined}
                readOnly={isPasswordReadonly ?? undefined}
            />
            <div className="d-flex flex-nowrap">
                <Checkbox label="show-email-address" value={showEmailAddress} onChange={setShowEmailAddress}/>&nbsp;&nbsp;
                <Checkbox label="show-password" value={showPassword} onChange={setShowPassword}/>&nbsp;&nbsp;
                <Checkbox label="email read-only" value={isEmailReadonly} onChange={setIsEmailReadonly}/>&nbsp;&nbsp;
                <Checkbox label="passwordread-only" value={isPasswordReadonly} onChange={setIsPasswordReadonly}/>
            </div>
            <button className="btn btn-secondary" onClick={resetCredentials}>Reset</button>&nbsp;&nbsp;
            <button className="btn btn-primary" onClick={submitCredentials}>Submit!</button>

            <div className="mt-3">
                Submitted:<br/>
                Email Address: {submittedEmailAddress}<br/>
                Password: {submittedPassword}<br/>
                Repeat: {submittedPasswordRepeat}
            </div>

            <div className="my-3">
                <ConfirmCard
                    title="Test für ConfirmCard mit etwas längerem Titel als gewöhnlich bla bla foo bar"
                    description="Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard."
                    primaryButtonTitle="Go Primary!"
                    onClickPrimary={() => console.log('You clicked primary')}
                    showCheckmark={true}
                    secondaryButtonTitle="Go Secondary!"
                    onClickSecondary={() => console.log('You clicked secondary')}
                />
            </div>

            <SlideDownCard
                title="Icons"
                closed={isIconsCardClosed}
                onToggle={setIsIconsCardClosed}
            >
                ArrowBackIcon: <ArrowBackIcon/>{' '}
                ArrowHookUpLeftIcon: <ArrowHookUpLeftIcon/>{' '}
                ArrowNextIcon: <ArrowNextIcon/>{' '}
                ArrowRightIcon: <ArrowRightIcon/>{' '}
                ArrowSortDownIcon: <ArrowSortDownIcon/>{' '}
                ArrowSortUpIcon: <ArrowSortUpIcon/>{' '}
                <br/>
                BillIcon: <BillIcon/>{' '}
                BurgerIcon: <BurgerIcon/>{' '}
                <br/>
                CalendarCancelIcon: <CalendarCancelIcon/>{' '}
                CalendarIcon: <CalendarIcon/>{' '}
                CardIcon: <CardIcon/>{' '}
                CashIcon: <CashIcon/>{' '}
                ChatHelpIcon: <ChatHelpIcon/>{' '}
                CheckIcon: <CheckIcon/>{' '}
                CheckIconFilled: <CheckIconFilled/>{' '}
                ClipboardTaskIcon: <ClipboardTaskIcon/>{' '}
                ClockIcon: <ClockIcon/>{' '}
                CloseIcon: <CloseIcon/>{' '}
                CopyIcon: <CopyIcon/>{' '}
                <br/>
                DeleteIcon: <DeleteIcon/>{' '}
                DocumentIcon: <DocumentIcon/>{' '}
                DownloadIcon: <DownloadIcon/>{' '}
                EditIcon: <EditIcon/>{' '}
                ErrorIconFilled: <ErrorIconFilled/>{' '}
                <br/>
                FoodIcon: <FoodIcon/>{' '}
                <br/>
                HistoryIcon: <HistoryIcon/>{' '}
                HomeIcon: <HomeIcon/>{' '}
                <br/>
                ImageIcon: <ImageIcon/>{' '}
                InfoIcon: <InfoIcon/>{' '}
                <br/>
                KeyIcon: <KeyIcon/>{' '}
                <br/>
                LeftIcon: <LeftIcon/>{' '}
                LoginIcon: <LoginIcon/>{' '}
                LogoutIcon: <LogoutIcon/>{' '}
                <br/>
                MailIcon: <MailIcon/>{' '}
                MinusIcon: <MinusIcon/>{' '}
                MoneyIcon: <MoneyIcon/>{' '}
                MultiplePersonIcon: <MultiplePersonIcon/>{' '}
                <br/>
                PayoutIcon: <PayoutIcon/>{' '}
                PersonIcon: <PersonIcon/>{' '}
                PlusIcon: <PlusIcon/>{' '}
                PreviewIcon: <PreviewIcon/>{' '}
                PrintIcon: <PrintIcon/>{' '}
                ProhibitedIcon: <ProhibitedIcon/>{' '}
                <br/>
                RefreshIcon: <RefreshIcon/>{' '}
                RightIcon: <RightIcon/>{' '}
                <br/>
                SearchIcon: <SearchIcon/>{' '}
                SendIcon: <SendIcon/>{' '}
                ServingIcon: <ServingIcon/>{' '}
                SettingIcon: <SettingIcon/>{' '}
                SignatureIcon: <SignatureIcon/>{' '}
                SpeechBubbleIcon: <SpeechBubbleIcon/>{' '}
                StarIconEmpty: <StarIconEmpty/>{' '}
                StarIconFilled: <StarIconFilled/>{' '}
                <br/>
                TextBulletListIcon: <TextBulletListIcon/>{' '}
                ToolIcon: <ToolIcon/>{' '}
                <br/>
                UploadIcon: <UploadIcon/>{' '}
                <br/>
                WalletCreditCardIcon: <WalletCreditCardIcon/>{' '}
                WarningIcon: <WarningIcon/>{' '}
                WrenchIcon: <WrenchIcon/>{' '}
            </SlideDownCard>
        </div>
    );
}

export default PlaygroundArea;
