import React, {useRef, useState} from 'react';
import {top} from '@popperjs/core/lib/enums';
import {useTranslation} from 'react-i18next';
import Tooltip from '../../common/component/Tooltip';
import DeleteIcon from '../../common/icons/DeleteIcon';
import Icon from '../../common/icons/Icon';

type Props = {
    isAccountDeletable: boolean,
    onClick: () => void,
    isParent: boolean
}

function AccountDeleteCard({isAccountDeletable, onClick, isParent}: Readonly<Props>) {
    const [t] = useTranslation();
    const tooltipRootRef = useRef<HTMLDivElement>(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    return <div className={`card ${isAccountDeletable ? 'card-hover' : ''} mt-3`} onClick={isAccountDeletable ? onClick : undefined}>
        <div className="card-body" ref={tooltipRootRef} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
            {
                isAccountDeletable &&
                <>
                    <Icon src={<DeleteIcon/>} className="mr-2"/>
                    <span className="font-weight-bold">{t('User.DELETION.DELETE_BUTTON')}</span>
                </>
            }
            {
                !isAccountDeletable &&
                <div>
                    <Icon src={<DeleteIcon/>} className="text-muted mr-2"/>
                    <span className="text-muted font-weight-bold">{t('User.DELETION.NOT_DELETABLE')}</span>
                    {
                        isParent &&
                        <Tooltip root={tooltipRootRef.current} placement={top} isVisible={tooltipVisible}>
                            <span className="pre-wrap">{t('User.DELETION.CANNOT_DELETE_WITH_NEGATIVE_BALANCE')}</span>
                        </Tooltip>
                    }
                </div>
            }
        </div>
    </div>;
}

export default AccountDeleteCard;
