import React from 'react';

const PrintIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M16.2525 3C17.4951 3 18.5025 4.00736 18.5025 5.25L18.502 6.003L19.2525 6.00358C21.0469 6.00533 22.5011
                  7.45877 22.5038 9.25244L22.5071 15.2497C22.509 16.4918 21.5021 17.5002 20.2591 17.5009L18.5
                  17.5V18.75C18.5 19.9926 17.4926 21 16.25 21H8.75C7.50736 21 6.5 19.9926 6.5 18.75L6.50091 17.5L4.75
                  17.5001C3.50736 17.5001 2.5 16.4927 2.5 15.2501V9.25358C2.5 7.45865 3.95507 6.00358 5.75 6.00358L6.499
                  6.003L6.5 5.25C6.5 4.00736 7.50736 3 8.75 3H16.2525ZM16.25 13.5H8.75C8.33579 13.5 8 13.8358 8
                  14.25V18.75C8 19.1642 8.33579 19.5 8.75 19.5H16.25C16.6642 19.5 17 19.1642 17 18.75V14.25C17 13.8358
                  16.6642 13.5 16.25 13.5ZM19.2518 7.50358H5.75C4.7835 7.50358 4 8.28708 4 9.25358V15.2501C4 15.6643
                  4.33579 16.0001 4.75 16.0001L6.5 16V14.25C6.5 13.0074 7.50736 12 8.75 12H16.25C17.4926 12 18.5 13.0074
                  18.5 14.25L18.5009 16.0001L20.2827 16.0005C20.6855 15.9874 21.0077 15.6567 21.0071 15.2512L21.0038
                  9.25398C21.0024 8.28801 20.2185 7.50452 19.2518 7.50358ZM16.2525 4.5H8.75C8.33579 4.5 8 4.83579 8
                  5.25L7.999 6.003H17.002L17.0025 5.25C17.0025 4.83579 16.6667 4.5 16.2525 4.5Z"
            />
        </svg>
    );
};

export default PrintIcon;
