import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import Diet from '../../common/component/Diet';
import EditIcon from '../../common/icons/EditIcon';
import Icon from '../../common/icons/Icon';
import {DefaultState} from '../../common/reducer/reducers';
import {addDays, convertDateToIsoDateString, formatDate} from '../../common/util/DateUtil';
import mealPlanApi from '../../order/api/mealPlanApi';
import {Child} from '../../types/Child';
import MealSlider from './MealSlider';

type Props = {
    child: Child
}

function ChildOrderOverview({child} : Readonly<Props>) {
    const [t] = useTranslation();
    const history = useHistory();

    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const fromDate = useMemo(() => new Date(), []);
    const toDate = useMemo(() => addDays(fromDate, 20), [fromDate]);

    const [meals, setMeals] = useState([]);

    const foodRestrictions = useMemo(() => { return [
        ...child.allergies
            .filter(allergy => allergy.verified)
            .map(allergy => allergy.nutritionClass)
    ];}, [child.allergies]);

    useMount(() => {
        if (child.group && !child.group.institutionPlacesOrders) {
            loadMeals();
        }
    });

    function loadMeals() {
        mealPlanApi.findByTimespanAndChildId(convertDateToIsoDateString(fromDate), convertDateToIsoDateString(toDate), child.id).then(response => {
            if (response.data.success) {
                setMeals(response.data.result);
            }
        });
    }

    function edit() {
        history.push(`/children/${child.id}`);
    }

    if (child.group?.institutionPlacesOrders) {
        return (
            <div className="card mb-4">
                <div className="card-header">
                    {child.fullName}
                </div>
                <div className="card-body text-muted d-flex align-items-center justify-content-center">
                    {t('Child.INSTITUTION_PLACES_ORDERS')}
                </div>
            </div>
        );
    }

    return (
        <div className="card h-100 mb-4">
            <div className="card-header d-flex justify-content-between">
                <div>
                    <span className="mr-2">{child.fullName}</span>
                    <Icon src={<EditIcon/>} className="icon-primary small" onClick={edit} title={t('Child.EDIT_TITLE')}/>
                </div>
                <div className="d-inline-flex align-items-center">
                    <Diet selectedItems={foodRestrictions}/>
                    <span className="ml-2">{t('Common.ORDERS')} {formatDate(currentLanguage, fromDate)} - {formatDate(currentLanguage, toDate)}</span>
                </div>
            </div>

            {
                child.group ?
                    <div className="card-body pl-0 pr-0">
                        <MealSlider meals={meals} fromDate={fromDate} toDate={toDate}/>
                    </div>
                    :
                    <div className="mr-2 ml-2 card-body alert alert-danger">
                        <div className="pre-wrap">
                            {t('MealPlan.NO_GROUP', {
                                childFirstName: child.firstName,
                                childLastName: child.lastName
                            })}
                        </div>
                    </div>
            }

            <div className="card-footer border-0 bg-transparent pt-0 text-right">
                {
                    child.group ?
                        <Link to={`/order/${child.id}`} className="btn btn-primary order-button">
                            {t('MealPlan.ORDER')}
                        </Link>
                        :
                        <button className="btn btn-primary order-button" disabled={true}>
                            {t('MealPlan.ORDER')}
                        </button>
                }
            </div>
        </div>
    );
}

export default ChildOrderOverview;
