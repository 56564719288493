import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import PageTitleWithWallet from '../../common/component/PageTitleWithWallet';
import {DefaultState} from '../../common/reducer/reducers';
import {Child} from '../../types/Child';
import {checkState} from '../../user/action/userActions';
import '../style/ChildrenOverview.scss';
import ChildOrderOverview from './ChildOrderOverview';

function ParentOverview() {
    const [t] = useTranslation();
    const account = useSelector((state: DefaultState) => state.user.account);
    const dispatch = useDispatch();
    const [children, setChildren] = useState<Array<Child>>([]);

    useEffect(() => {
        if (account?.children?.length) {
            const childrenCopy = [...account.children];

            setChildren(childrenCopy);
        }
    }, [account]);

    useEffect(() => {
        dispatch(checkState());
    }, [dispatch]);

    if (!account || !children) {
        return null;
    }

    return (
        <div className="container">
            <PageTitleWithWallet
                headerTitle={t('ChildrenOverview.TITLE')}
                balance={account.balance}
                displayBig={true}
                pricePositionOffset="-20px"
            />
            <div className="no-gutters children-overview">
                {
                    children
                        .filter(child => !child.group?.institutionPlacesOrders)
                        .map(child => <ChildOrderOverview child={child} key={child.id}/>)
                }
            </div>
        </div>
    );
}

export default ParentOverview;
