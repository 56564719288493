import React from 'react';

const CalendarCancelIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12
                  17.5C12 14.4624 14.4624 12 17.5 12ZM15.0238 15.0241C14.8285 15.2194 14.8285 15.536 15.0238 15.7312L16.793
                  17.501L15.0264 19.2675C14.8311 19.4628 14.8311 19.7793 15.0264 19.9746C15.2217 20.1699 15.5382 20.1699
                  15.7335 19.9746L17.5 18.208L19.2694 19.9768C19.4647 20.1721 19.7812 20.1721 19.9765 19.9768C20.1718 19.7816
                  20.1718 19.465 19.9765 19.2697L18.208 17.501L19.9793 15.7313C20.1745 15.536 20.1745 15.2194 19.9793
                  15.0242C19.784 14.8289 19.4674 14.8289 19.2722 15.0242L17.501 16.794L15.7309 15.0241C15.5356 14.8289
                  15.2191 14.8289 15.0238 15.0241ZM17.75 3C19.5449 3 21 4.45507 21 6.25L21.0012 12.0226C20.5378 11.7258
                  20.0342 11.4861 19.5004 11.3136L19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5L11.3136 19.5004C11.4861
                  20.0342 11.7258 20.5378 12.0226 21.0012L6.25 21C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25
                  3H17.75ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z"/>
        </svg>
    );
};

export default CalendarCancelIcon;
