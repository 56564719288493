import React from 'react';

interface Props {
    size?: number;
}

const CashIcon = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor"
                  d="M6.14356 4.27749C5.32682 4.75248 5.00891 5.29789 5.00891 5.74589C5.00891 6.19389 5.32682 6.7393 6.14356 7.21429C6.85631 7.62881 7.85145 7.92125 8.99148
                   7.97896C8.80914 8.40484 8.70215 8.87601 8.70215 9.39146L8.7034 9.39325H8.70215V9.46379C7.44326 9.3716 6.28908 9.03415 5.38946 8.51095C5.25891 8.43503 5.13165
                   8.35377 5.00891 8.26733V10.1462C5.00891 10.5942 5.32682 11.1396 6.14356 11.6146C6.79533 11.9936 7.68324 12.2706 8.70215 12.3594V13.8641C7.44326 13.7719 6.28908
                   13.4344 5.38946 12.9112C5.25891 12.8353 5.13165 12.7541 5.00891 12.6676V14.5828C5.00891 15.0308 5.32682 15.5763 6.14356 16.0512C6.79533 16.4303 7.68324 16.7073
                   8.70215 16.796V18.2435C8.70215 18.2627 8.7023 18.2818 8.70259 18.3008C7.44353 18.2086 6.28918 17.8712 5.38946 17.3479C4.34325 16.7395 3.50891 15.7881 3.50891
                   14.5828V10.1462V5.92468H3.51509C3.511 5.86573 3.50891 5.80613 3.50891 5.74589C3.50891 4.54062 4.34325 3.58927 5.38946 2.98083C6.45447 2.36144 7.87626 2.00238
                   9.40617 2.00238C10.9361 2.00238 12.3579 2.36144 13.4229 2.98083C14.1299 3.39202 14.7402 3.95982 15.0616 4.65677C14.4999 4.68552 13.9501 4.7585 13.4235
                   4.8734C13.2447 4.67065 12.997 4.46838 12.6688 4.27749C11.8709 3.81344 10.719 3.50238 9.40617 3.50238C8.09333 3.50238 6.94149 3.81344 6.14356 4.27749ZM11.2021
                   9.39146C11.2021 8.94204 11.521 8.39535 12.3394 7.91943C13.1389 7.45445 14.293 7.14282 15.6082 7.14282C16.9235 7.14282 18.0776 7.45445 18.8771 7.91943C19.6954
                   8.39535 20.0143 8.94204 20.0143 9.39146C20.0143 9.84088 19.6954 10.3876 18.8771 10.8635C18.0776 11.3285 16.9235 11.6401 15.6082 11.6401C14.293 11.6401 13.1389
                   11.3285 12.3394 10.8635C11.521 10.3876 11.2021 9.84088 11.2021 9.39146ZM15.6082 5.64282C14.0759 5.64282 12.6519 6.00246 11.5853 6.62277C10.5375 7.23215 9.70215
                   8.18478 9.70215 9.39146C9.70215 9.4518 9.70424 9.5115 9.70834 9.57056H9.70215V13.7993V18.2435C9.70215 19.4502 10.5375 20.4029 11.5853 21.0122C12.6519 21.6325
                   14.0759 21.9922 15.6082 21.9922C17.1406 21.9922 18.5646 21.6325 19.6312 21.0122C20.679 20.4029 21.5143 19.4502 21.5143 18.2435V13.7993V9.57056H21.5081C21.5122
                   9.5115 21.5143 9.4518 21.5143 9.39146C21.5143 8.18478 20.679 7.23215 19.6312 6.62277C18.5646 6.00246 17.1406 5.64282 15.6082 5.64282ZM11.2021
                   18.2435V16.3226C11.3257 16.4097 11.4538 16.4915 11.5853 16.568C12.6519 17.1883 14.0759 17.548 15.6082 17.548C17.1406 17.548 18.5646 17.1883 19.6312
                   16.568C19.7626 16.4915 19.8908 16.4097 20.0143 16.3226V18.2435C20.0143 18.693 19.6954 19.2397 18.8771 19.7156C18.0776 20.1806 16.9235 20.4922 15.6082
                   20.4922C14.293 20.4922 13.1389 20.1806 12.3394 19.7156C11.521 19.2397 11.2021 18.693 11.2021 18.2435ZM18.8771 15.2713C19.6954 14.7954 20.0143 14.2487 20.0143
                   13.7993V11.9147C19.8908 12.0018 19.7626 12.0837 19.6312 12.1601C18.5646 12.7805 17.1406 13.1401 15.6082 13.1401C14.0759 13.1401 12.6519 12.7805 11.5853
                   12.1601C11.4538 12.0837 11.3257 12.0018 11.2021 11.9147V13.7993C11.2021 14.2487 11.521 14.7954 12.3394 15.2713C13.1389 15.7363 14.293 16.048 15.6082
                   16.048C16.9235 16.048 18.0776 15.7363 18.8771 15.2713Z"
            />
        </svg>
    );
};

export default CashIcon;
