import React, {ReactNode} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import '../../style/IconNavLink.scss';

type Props = {
    icon: ReactNode,
    exact?: boolean,
    to: string,
    additionalPaths?: Array<string>
    children: ReactNode,
    keyValue?: string
}

function IconNavLink({
                         icon,
                         exact = false,
                         to,
                         additionalPaths = [],
                         children,
                         keyValue
                     } : Readonly<Props>) {
    const { pathname } = useLocation();

    return (
        <NavLink
            id="icon-nav-link"
            exact={exact}
            to={to}
            key={keyValue}
            isActive={(match) => !!match || !!additionalPaths.find((candidate) => pathname.startsWith(candidate + '/'))}
        >
            <div className="nav-link">
                <span className="nav-text">
                    <span className="icon-link">
                        {icon}
                    </span>
                    {children}
                </span>
            </div>
        </NavLink>
    );
}

export default IconNavLink;
