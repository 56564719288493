import React from 'react';

const WarningIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M9.40934 0.782162C10.4491 0.206252 11.7471 0.540892 12.3849 1.5224L12.4677 1.66023L20.2267 15.6685C20.4115 16.0021 20.5084 16.3773 20.5084 16.7587C20.5084
                  17.9495 19.5833 18.9243 18.4125 19.0035L18.2584 19.0087H2.74272C2.36141 19.0087 1.98636 18.9118 1.65277 18.7271C0.612931 18.1513 0.207891 16.8734 0.701451
                  15.812L0.774351 15.6687L8.53114 1.66046C8.73564 1.2911 9.04004 0.986742 9.40934 0.782162ZM18.9145 16.3953L11.1556 2.38702C10.9549 2.02467 10.4984 1.89363
                  10.1361 2.09433C10.0438 2.14547 9.96364 2.21533 9.90054 2.299L9.84344 2.3871L2.0866 16.3954C1.88594 16.7577 2.01704 17.2141 2.37941 17.4148C2.4628 17.461
                  2.55397 17.4907 2.64797 17.5027L2.74272 17.5087H18.2584C18.6726 17.5087 19.0084 17.1729 19.0084 16.7587C19.0084 16.6633 18.9903 16.5691 18.9552
                  16.4811L18.9145 16.3953L11.1556 2.38702L18.9145 16.3953ZM10.5004 14.0018C11.0519 14.0018 11.499 14.4489 11.499 15.0004C11.499 15.552 11.0519 15.9991
                  10.5004 15.9991C9.94884 15.9991 9.50174 15.552 9.50174 15.0004C9.50174 14.4489 9.94884 14.0018 10.5004 14.0018ZM10.4962 6.49955C10.8758 6.49925 11.1899
                  6.78115 11.2398 7.14718L11.2468 7.24895L11.2504 11.7505C11.2507 12.1648 10.9152 12.5008 10.501 12.5011C10.1213 12.5014 9.80724 12.2195 9.75734
                  11.8535L9.75044 11.7517L9.74684 7.25015C9.74644 6.83594 10.0819 6.49988 10.4962 6.49955Z"/>
        </svg>
    );
};

export default WarningIcon;
