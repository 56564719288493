import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {buildInformationApi} from '../api/buildInformationApi';
import '../style/Footer.scss';
import Gabel1DomainType from '../enums/Gabel1DomainType';
import GabelEinsLogo from '../icons/GabelEinsLogo';
import {DefaultState} from '../reducer/reducers';
import {getFileExtension} from '../util/FileUtil';
import LanguageSelector from './LanguageSelector';

function Footer() {
    const [t] = useTranslation();
    const [version, setVersion] = useState<string>();
    const catererLogo = useSelector((state: DefaultState) => state.caterer.logo);
    const logoExtension = getFileExtension(catererLogo?.data);
    const domainType = useSelector((state: DefaultState) => state.domainContext.domainType);

    useEffect(() => {
        buildInformationApi.getBuildInformation().then(res => {
            if (res.data.build) {
                setVersion(`${res.data.build.version} - ${res.data.build.time}\n(${process.env.REACT_APP_VERSION})`);
            }
        });
    }, []);

    return (
        <footer>
            <div className="footer-container">
                {
                    domainType !== Gabel1DomainType.SUPPORT &&
                    <>
                        <Link to="/terms-and-conditions">{t('Footer.TERMS_AND_CONDITIONS')}</Link>
                        <Link to="/imprint">{t('Footer.IMPRINT')}</Link>
                        <Link to="/privacy-policy">{t('Footer.PRIVACY')}</Link>
                    </>
                }
                <Link to="/faq-and-contact">{t('Footer.FAQ_AND_CONTACT')}</Link>
                <div className="right-content">
                    <div className="mr-4">
                        <LanguageSelector/>
                    </div>
                    {
                        catererLogo?.data &&
                        <div className="logo-container" title={version}>
                            <img src={`data:image/${logoExtension};base64,${catererLogo.data}`} alt="small logo"/>
                        </div>
                    }
                    {
                        !catererLogo?.data && catererLogo?.tried &&
                        <div className="ml-3" title={version}>
                            <GabelEinsLogo size={0.65}/>
                        </div>
                    }
                </div>
            </div>
        </footer>
    );
}

export default Footer;
