import {AccountType} from '../AccountType';
import {AccountValidation} from './AccountValidation';

export const initialAccountValidation: AccountValidation = {
    language: true,
    login: {
        catererId: true,
        emailAddress: true,
        password: true,
        passwordRepeat: true
    },
    person: {
        salutation: true,
        firstName: true,
        lastName: true
    },
    address: {
        street: true,
        houseNumber: true,
        zip: true,
        city: true
    }
};

export function validateAccount(account: Partial<AccountType>, skipPassword = true) : AccountValidation {
    return {
        language: !!account.language,
        login: {
            catererId: !!account.login?.catererId,
            emailAddress: !!account.login?.emailAddress,
            password: skipPassword || !!account.login?.password,
            passwordRepeat: skipPassword || account.login?.password === account.login?.passwordRepeat
        },
        person: {
            salutation: !!account.person?.salutation,
            firstName: !!account.person?.firstName,
            lastName: !!account.person?.lastName
        },
        address: {
            street: !!account.address?.street,
            houseNumber: !!account.address?.houseNumber,
            zip: !!account.address?.zip,
            city: !!account.address?.city,
        }
    };
}
