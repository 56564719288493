import React from 'react';
import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ChatHelpIcon from '../../icons/ChatHelpIcon';
import HistoryIcon from '../../icons/HistoryIcon';
import HomeIcon from '../../icons/HomeIcon';
import PersonIcon from '../../icons/PersonIcon';
import WalletCreditCardIcon from '../../icons/WalletCreditCardIcon';
import IconNavLink from './IconNavLink';

function NavigationParent() {
    const [t] = useTranslation();

    return (
        <Nav className="ml-4 ml-lg-0">
            <IconNavLink exact={true} to="/" icon={<HomeIcon/>}>{t('Navigation.START')}</IconNavLink>
            <IconNavLink to="/finances" icon={<WalletCreditCardIcon/>}>{t('Navigation.FINANCE')}</IconNavLink>
            <IconNavLink to="/my-profile" additionalPaths={['/children']} keyValue="/my-profile" icon={<PersonIcon/>}>{t('Navigation.PROFILE')}</IconNavLink>
            <IconNavLink to="/activities" keyValue="/activities" icon={<HistoryIcon/>}>{t('Navigation.HISTORY')}</IconNavLink>
            <IconNavLink to="/faq-and-contact" keyValue="/faq-and-contact" icon={<ChatHelpIcon/>}>{t('Navigation.FAQ')}</IconNavLink>
        </Nav>
    );
}

export default NavigationParent;
