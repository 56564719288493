import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import accountApi from '../../account/api/accountApi';
import {changeLanguage, SupportedLanguages} from '../../i18n/action/i18nActions';
import '../style/LanguageSelector.scss';
import {DefaultState} from '../reducer/reducers';

function LanguageSelector() {
    const dispatch = useDispatch();
    const account = useSelector((state: DefaultState) => state.user.account);
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const languages = useSelector((state: DefaultState) => state.i18n.languages);

    function handleChange(newLanguage: SupportedLanguages) {
        dispatch(changeLanguage(newLanguage));
        if (account?.id) {
            accountApi.updateLanguage(account.id, newLanguage).then();
        }
    }

    return (
        <ul className="language-selector">
            {
                languages.map(language =>
                    language.value !== currentLanguage &&
                    <li key={language.value}>
                        <button className="btn btn-link btn-secondary" onClick={() => handleChange(language.value)}>
                            {language.label}
                        </button>
                    </li>
                )
            }
        </ul>
    );
}

export default LanguageSelector;
