import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountType} from '../../types/AccountType';
import EditIcon from '../../common/icons/EditIcon';
import Icon from '../../common/icons/Icon';
import KeyIcon from '../../common/icons/KeyIcon';
import PlusIcon from '../../common/icons/PlusIcon';
import ParentProfileViewOptions from '../enum/ParentProfileViewOptions';
import ChildSummaryCard from './ChildSummaryCard';
import ParentAccountDeletion from './ParentAccountDeletion';

type Props = {
    account: Partial<AccountType>,
    onShowEditParent: (view: string) => void,
    onGotoChildSetup: () => void,
    onGotoEditChild: (childId: number) => void
}

function ParentProfileOverview({account, onShowEditParent, onGotoChildSetup, onGotoEditChild}: Readonly<Props>) {
    const [t] = useTranslation();

    if (!account.person || !account.login) {
        return null;
    }

    return <div className="container">
        <h1 className="mb-2">{account.person.firstName} {account.person.lastName}</h1>
        <p className="customer-number">{t('ParentOverview.CUSTOMER_ID')}: <span className="font-weight-bold">{account.customerNumber}</span></p>

        <div className="row">
            <div className="col-12 col-md-4">
                <h2>
                    {t('Navigation.PROFILE')}
                </h2>
                <div className="card">
                    <div className="card-body">
                        <Icon
                            src={<EditIcon/>}
                            className="icon-primary float-right"
                            onClick={() => onShowEditParent(ParentProfileViewOptions.PARENT_BASEDATA_EDIT)}
                        />
                        {
                            account.person.salutation !== 'MX' &&
                            <span>{t('Salutation.' + account.person.salutation)}<br/></span>
                        }
                        <span className="font-weight-bold">{account.person.firstName} {account.person.lastName}<br/></span>
                        <a href={`mailto:${account.login.emailAddress}`}>{account.login.emailAddress}</a><br/><br/>

                        {
                            account.address?.additionalAddressInformation &&
                            <span>{account.address.additionalAddressInformation}<br/></span>
                        }
                        {
                            account.address &&
                            <>
                                <span>{account.address.street} {account.address.houseNumber}<br/></span>
                                <span>{account.address.zip} {account.address.city}</span>
                            </>
                        }
                    </div>
                </div>

                <div className="card card-hover mt-3" onClick={() => onShowEditParent(ParentProfileViewOptions.CHANGE_PASSWORD)}>
                    <div className="card-body">
                        <Icon src={<KeyIcon/>} className="mr-2"/>
                        <span className="font-weight-bold">{t('User.CHANGE_PASSWORD')}</span>
                    </div>
                </div>

                <ParentAccountDeletion accountId={account.id!} deletionWorkflowType="PARENT"/>
            </div>

            <div className="col-12 col-md-8">
                <h2 className="mt-4 mt-md-0">
                    <div className="d-flex justify-content-between">
                        {(account.children && account.children.length > 0) && t('Common.CHILDREN')}
                        <button className="btn btn-secondary btn-sm mr-2 mr-sm-0" onClick={onGotoChildSetup}>
                            <Icon src={<PlusIcon size={0.9}/>}/>
                            <span className="d-none d-sm-block ml-2">{t('Parent.ADD_CHILD')}</span>
                        </button>
                    </div>
                </h2>
                {
                    account.children?.map(child => <ChildSummaryCard child={child} key={child.id} onClick={() => onGotoEditChild(child.id)}/>)
                }
            </div>
        </div>

    </div>;
}

export default ParentProfileOverview;
