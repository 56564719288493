import React from 'react';

type Props = {
    src: string,
    marginBottom: number,
    alt: string
}

function BackgroundIllustration({src, marginBottom, alt}: Readonly<Props>) {
    return <img
        className="d-none d-xl-block"
        src={src}
        style={{float: 'right', position: 'relative', bottom: marginBottom, zIndex: -1}}
        alt={alt}
    />;
}

export default BackgroundIllustration;
