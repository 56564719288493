import React from 'react';

type Props = {
    size?: number;
}

function ArrowHookUpLeftIcon({size = 1}: Readonly<Props>) {
    return <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.1316 17.6107L18.1316 17.6107C17.2097 18.7466 15.8262 19.5 14 19.5H7.75C7.61193 19.5 7.5 19.3881 7.5 19.25C7.5 19.1119 7.61193 19
            7.75 19H14C15.6737 19 16.9152 18.3159 17.7434 17.2956L17.7434 17.2956C18.5763 16.2692 19 14.8902 19 13.5C19 12.1098 18.5763 10.7308
            17.7434 9.70443L17.7434 9.70439C16.9153 8.6841 15.6738 8 14 8H7.56066H6.35357L7.2071 8.85355L9.67674 11.3232L10.0107 10.9893L9.67674
            11.3233C9.77438 11.4209 9.77438 11.5791 9.67674 11.6767L9.67672 11.6768C9.57914 11.7744 9.42086 11.7744 9.32323 11.6768L9.32322
            11.6767L5.57322 7.92677C5.47559 7.82915 5.47559 7.67085 5.57322 7.57322L9.32322 3.82322C9.42086 3.72558 9.57914 3.72561 9.67673
            3.8232L9.67675 3.82323C9.77438 3.92085 9.77438 4.07914 9.67675 4.17677L9.67674 4.17678L7.2071 6.64645L6.35356 7.5H7.56066H14C15.8262 7.5
            17.2097 8.25337 18.1316 9.38932L18.1316 9.38935C19.0485 10.5191 19.5 12.0149 19.5 13.5C19.5 14.9851 19.0485 16.4809 18.1316 17.6107Z"
            stroke="currentColor"/>
    </svg>;
}

export default ArrowHookUpLeftIcon;
