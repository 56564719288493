import React from 'react';

type ProhibitedIconProps = {
    size?: number
}

function ProhibitedIcon({size = 1}: ProhibitedIconProps) {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5
                2ZM19.0173 6.54309L7.04309 18.5173C8.51955 19.7549 10.4228 20.5 12.5 20.5C17.1944 20.5 21 16.6944 21 12C21 9.92276 20.2549 8.01955
                 19.0173 6.54309ZM12.5 3.5C7.80558 3.5 4 7.30558 4 12C4 14.0772 4.74513 15.9804 5.98268 17.4569L17.9569 5.48268C16.4804 4.24513
                 14.5772 3.5 12.5 3.5Z"/>

        </svg>

    );
}

export default ProhibitedIcon;
