import React from 'react';
import {useTranslation} from 'react-i18next';
import FormButtons from '../../common/component/form/FormButtons';
import LoginCredentials from '../../common/component/form/LoginCredentials';
import KeyIcon from '../../common/icons/KeyIcon';
import {AccountValidation} from '../../types/account/AccountValidation';
import {AccountType} from '../../types/AccountType';
import Illustration from '../../assets/img/bowl_of_lettuce.png';

type Props = {
    account: Partial<AccountType>,
    onUpdateAccount: (key: string, value: string, subObject?: string) => void,
    accountValidation: AccountValidation,
    onSave: () => void,
    onCancel: () => void
}

function ParentProfileChangePassword({account, onUpdateAccount, accountValidation, onSave, onCancel}: Readonly<Props>) {
    const [t] = useTranslation();

    if (!account.login) {
        return null;
    }

    return <div className="container">
        <div className="center-screen">
            <div className="parent-profile-header d-flex justify-content-between align-items-baseline">
                <div>
                    <KeyIcon size={1.3}/>
                    <span className="header-text">{t('User.CHANGE_PASSWORD')}</span>
                </div>
                <img src={Illustration} className="d-none d-lg-block mr-5" alt="bowl of lettuce"/>
            </div>

            <div className="card">
                <div className="card-body">
                    <LoginCredentials
                        login={account.login}
                        onChange={(key, value) => onUpdateAccount(key, value, 'login')}
                        validation={accountValidation.login}
                        emailReadOnly={true}
                    />
                </div>

                <div className="card-footer">
                    <FormButtons
                        onSave={onSave}
                        onCancel={onCancel}
                        sticky={false}
                    />
                </div>
            </div>
        </div>
    </div>;
}

export default ParentProfileChangePassword;
