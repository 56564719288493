import React from 'react';

interface iconProps {
    size?: number;
}

const SendIcon = ({
                      size = 1
                  }: iconProps) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M1.80958 6.18837L9.40999 11.6598L11.317 20.8285L11.3434 20.9253C11.5447 21.5131 12.3678 21.6177 12.6979 21.0557L22.8922
                  3.70417L22.9424 3.60293C23.1509 3.09141 22.7373 2.51402 22.1615 2.57893L2.16372 4.83441L2.06488 4.85178C1.46099 4.99773
                  1.28061 5.80756 1.80958 6.18837ZM10.1061 10.3127L4.26403 6.10703L20.8391 4.23762L12.3896 18.6193L10.9231 11.5707L16.4818
                  7.96159L16.5633 7.90042C16.8433 7.65943 16.9091 7.24255 16.7023 6.92411L16.6411 6.84249C16.4001 6.56247 15.9833 6.49679 15.6648
                  6.70359L10.1061 10.3127ZM1.34442 11.7229C0.997028 11.9485 0.898296 12.413 1.12389 12.7604C1.34949 13.1078 1.81399 13.2065 2.16137
                  12.9809L4.25805 11.6193C4.60544 11.3937 4.70417 10.9292 4.47857 10.5818C4.25298 10.2345 3.78848 10.1357 3.44109 10.3613L1.34442
                  11.7229ZM1.55138 16.9452C1.32579 16.5979 1.42452 16.1334 1.77191 15.9078L6.80393 12.6399C7.15132 12.4143 7.61582 12.5131 7.84141
                  12.8605C8.06701 13.2078 7.96828 13.6723 7.62089 13.8979L2.58887 17.1658C2.24148 17.3914 1.77698 17.2926 1.55138 16.9452ZM5.2575
                  18.1361C4.91011 18.3617 4.81138 18.8262 5.03698 19.1736C5.26258 19.521 5.72707 19.6197 6.07446 19.3941L8.17114 18.0325C8.51853
                  17.8069 8.61726 17.3424 8.39166 16.995C8.16606 16.6476 7.70157 16.5489 7.35418 16.7745L5.2575 18.1361Z"/>
        </svg>
    );
};

export default SendIcon;
