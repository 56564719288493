import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';
import accountApi from '../../account/api/accountApi';
import {setAccountToEdit} from '../../administration/support/redux/supportDataActions';
import {navigate, unmarkChanges} from '../../common/action/pageLeaveActions';
import BackTo from '../../common/component/BackTo';
import PageLeaveGuard from '../../common/component/PageLeaveGuard';
import {DefaultState} from '../../common/reducer/reducers';
import {convertToTypedParent} from '../../common/util/ParentUtil';
import {showMessage} from '../../message/action/messageActions';
import {AccountType} from '../../types/AccountType';
import {Child} from '../../types/Child';
import {ParentType} from '../../types/ParentType';
import {updateAccount} from '../../user/action/userActions';
import userApi from '../../user/api/userApi';
import ChildSetup from './childSetup/ChildSetup';

interface MatchParams {
    id: string;
}

function ChildAdd() {
    const dispatch = useDispatch();
    const match = useRouteMatch<MatchParams>();
    const account = useSelector((state: DefaultState) => state?.user?.account) as AccountType;
    const children = useSelector((state: DefaultState) => state?.user?.account?.children);
    const location = useLocation();
    const backLocation = {...location, path: '/my-profile', pathname: '/my-profile'} as unknown as Location;
    const hasChanges = useSelector((state: DefaultState) => state.pageLeave.hasUnsavedChanges);
    const isOnboarding = useSelector((state: DefaultState) => state.onboarding.isOnboarding);
    const childId = !!match && !!match.params ? parseInt(match.params?.id) : null;

    const [parent, setParent] = useState<ParentType>();

    const loadParent = useCallback(() => {
        accountApi.findById(account.id, true).then(response => {
            if (response.data.success) {
                const accountResult = response.data.result;
                setParent(convertToTypedParent(accountResult));
                // Set accountToEdit already now, so it is there when edit icon is clicked
                dispatch(setAccountToEdit(accountResult as AccountType));
            }
        });
    }, [account, dispatch]);

    useEffect(() => {
        loadParent();
    }, [loadParent]);

    const handleSave = (updatedChild: Child | undefined) => {
        loadParent();
        finishSave(updatedChild);
    };

    function cancel(updatedChild: Child | undefined) {
        loadParent();
        if (updatedChild) {
            finishSave(updatedChild);
        } else {
            dispatch(unmarkChanges());
            dispatch(navigate(backLocation));
        }
    }

    const finishSave = (updatedChild: Child | undefined) => {
        if (!updatedChild) {
            dispatch(unmarkChanges());
            dispatch(navigate(backLocation));
            return;
        }

        reloadMe(() => {
            const childBefore = childId && children ? children.find(c => c.id === childId) : null;
            const welfareBefore = childBefore && childBefore.childWelfare && childBefore.childWelfare.length > 0;

            if (!welfareBefore && updatedChild.childWelfare && updatedChild.childWelfare.length > 0) {
                dispatch(showMessage('ChildSettings.WELFARE_CONFIRMATION_INFO'));
            }

            dispatch(unmarkChanges());
            dispatch(navigate(backLocation));
            dispatch(showMessage('Child.SAVE_SUCCESS', {child: `${updatedChild.firstName} ${updatedChild.lastName}`}));
        });
    }

    function reloadMe(onLoaded? : () => void) {
        userApi.findMe().then(res => {
            if (res.data.success && res.data.result.account) {
                dispatch(updateAccount(res.data.result.account));
            }

            if (onLoaded) {
                onLoaded();
            }
        });
    }

    return (
        <div className="container">
            <div className="center-screen">
                <BackTo path="/" labelKey="ChildrenOverview.TITLE"/>

                <div className="mt-4">
                    {
                        !!parent && <ChildSetup catererId={parent.catererId}
                                                accountId={parent.id}
                                                onSaveAndContinue={reloadMe}
                                                onSaveAndDone={handleSave}
                                                onCancel={cancel}
                                                isOnboarding={isOnboarding}/>
                    }
                </div>
            </div>

            <PageLeaveGuard hasChanges={hasChanges}/>
        </div>
    );
}

export default ChildAdd;
