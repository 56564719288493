import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {PersonValidation} from '../../../types/account/PersonValidation';
import {PersonType} from '../../../types/PersonType';
import Salutation from '../../enums/Salutation';
import Dropdown from './Dropdown';
import TextInput from './TextInput';

type Props = {
    person: Partial<PersonType>,
    validation: PersonValidation,
    onChange: (key: string, value: string) => void,
    readonly?: boolean
}

const salutations = [Salutation.MRS, Salutation.MR, Salutation.MX];

function PersonEdit({person, validation, onChange, readonly = false}: Readonly<Props>) {
    const [t] = useTranslation();

    const salutationOptions = useMemo(() => {
        return salutations.map(salutation => ({
            label: t(`Salutation.${salutation}`),
            value: salutation
        }))
    }, [t]);

    return <>
        <div className="row">
            <div className="col-12 col-md-4">
                <Dropdown
                    label={t('User.SALUTATION')}
                    options={salutationOptions}
                    value={person.salutation ? {value: person.salutation, label: t(`Salutation.${person.salutation}`)} : undefined}
                    onChange={option => onChange('salutation', option.value)}
                    isValid={validation.salutation}
                    isDisabled={readonly}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-lg-6">
                <TextInput
                    id="firstName"
                    label={t('User.FIRST_NAME')}
                    value={person?.firstName}
                    onChange={value => onChange('firstName', value)}
                    isValid={validation.firstName}
                    required={true}
                    readonly={readonly}
                />
            </div>
            <div className="col-12 col-lg-6">
                <TextInput
                    id="lastName"
                    label={t('User.LAST_NAME')}
                    value={person?.lastName}
                    onChange={value => onChange('lastName', value)}
                    isValid={validation.lastName}
                    required={true}
                    readonly={readonly}
                />
            </div>
        </div>
    </>;
}

export default PersonEdit;
