import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AddressEdit from '../../common/component/Address';
import BackgroundIllustration from '../../common/component/BackgroundIllustration';
import Dropdown, {DropdownOption} from '../../common/component/form/Dropdown';
import FormButtons from '../../common/component/form/FormButtons';
import PersonEdit from '../../common/component/form/PersonEdit';
import PersonIcon from '../../common/icons/PersonIcon';
import {DefaultState} from '../../common/reducer/reducers';
import {AccountValidation} from '../../types/account/AccountValidation';
import {AccountType} from '../../types/AccountType';
import {Address} from '../../types/Address';
import IllustrationSource from '../../assets/img/fish_and_broccoli.png';

type Props = {
    account: Partial<AccountType>,
    onUpdateAccount: (key: string, value: string, subObject?: string) => void,
    onUpdateAccountAddress: (address: Address) => void,
    accountValidation: AccountValidation,
    onSave: () => void,
    onCancel: () => void
}

function ParentProfileEditBase({account, onUpdateAccount, onUpdateAccountAddress, accountValidation, onSave, onCancel}: Readonly<Props>) {
    const [t] = useTranslation();

    const languageOptions = useSelector((state: DefaultState) => state.i18n.languages);

    const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption>({value: account.language ?? 'de-DE'});

    function handleLanguageChange(languageOption: DropdownOption) {
        setSelectedLanguage(languageOption);
        onUpdateAccount('language', languageOption.value);
    }

    return <>
        <div className="container">
            <div className="center-screen">
                <div className="parent-profile-header">
                    <PersonIcon size={1.3}/>
                    <span className="header-text">{t('User.HEADER_PROFILE')}</span>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <Dropdown
                                    label={t('User.LANGUAGE')}
                                    options={languageOptions}
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                    required={true}
                                    isValid={accountValidation.language}
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <PersonEdit
                                person={account.person || {}}
                                validation={accountValidation.person}
                                onChange={(key: string, value: string) => onUpdateAccount(key, value, 'person')}
                            />
                        </div>

                        <h3>{t('User.ADDRESS')}</h3>
                        {/* @ts-ignore */}
                        <AddressEdit
                            address={account.address || {}}
                            validation={accountValidation.address}
                            handleChange={onUpdateAccountAddress}
                        />
                    </div>

                    <div className="card-footer">
                        <FormButtons
                            onSave={onSave}
                            onCancel={onCancel}
                            sticky={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <BackgroundIllustration src={IllustrationSource} marginBottom={800} alt="fish and broccoli"/>
    </>;
}

export default ParentProfileEditBase;
