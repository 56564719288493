import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMount} from 'react-use';
import '../style/ChildSummaryCard.scss';
import {hasVerifiedWelfare} from '../../child/util/ChildWelfareUtils';
import ChildSubscriptionState from '../../common/component/ChildSubscriptionState';
import Tag from '../../common/component/Tag';
import CalendarIcon from '../../common/icons/CalendarIcon';
import CashIcon from '../../common/icons/CashIcon';
import FoodIcon from '../../common/icons/FoodIcon';
import Icon from '../../common/icons/Icon';
import subscriptionApi from '../../order/api/subscriptionApi';
import {Child} from '../../types/Child';
import {ChildSubscription} from '../../types/child/ChildSubscription';

type Props = {
    child: Child,
    onClick?: () => void,
    isDeleted?: boolean,
    isSupportView?: boolean
}

function ChildSummaryCard({child, onClick, isDeleted = false, isSupportView = false}: Readonly<Props>) {
    const [t] = useTranslation();

    const [subscription, setSubscription] = useState<ChildSubscription>();

    useMount(() => {
        subscriptionApi.findByChildId(child.id).then(response => {
            if (response.data.success && response.data.result) {
                setSubscription(response.data.result);
            }
        });
    });

    return <div className="card card-hover child-detail-card mb-3" key={child.id} onClick={onClick}>
        <div className={'card-header' + (isDeleted ? ' deleted' : '')}>
            {child.fullName}
        </div>
        <div className="card-body">
            {
                child.group && !isSupportView &&
                <>
                    <span className="text-primary font-weight-bold">{child.group.name}</span>
                    <br/>
                    <span className="text-primary">{child.group.institutionName}</span>
                    <br/><br/>
                </>
            }
            {
                child.group && isSupportView &&
                <>
                    <a href={`/support/institutions/groups/${child.group.id}`}>
                        <span className="text-primary font-weight-bold">{child.group.name}</span>
                    </a>
                    <br/>
                    <a href={`/support/institutions/${child.group.institutionId}`}>
                        <span className="text-primary">{child.group.institutionName}</span>
                    </a>
                    <br/><br/>
                </>
            }
            {
                !child.group &&
                <><span className="text-muted">{t('Task.NO_GROUP_MESSAGE')}</span><br/><br/></>
            }

            {
                !child.group?.institutionPlacesOrders &&
                <div className="child-summary-grid">
                    <div className="child-summary-grid-item">
                        <div className="grid-item-first-col">
                            <Icon src={<CalendarIcon/>} className="mr-1"/>{t('Subscription.TITLE')}:
                        </div>
                        <div>
                            {
                                subscription ?
                                    <ChildSubscriptionState subscription={subscription}/>
                                    : <><span className="text-muted">{t('Common.NOT_ACTIVE')}</span><br/></>
                            }
                        </div>
                    </div>

                    <div className="child-summary-grid-item">
                        <div className="grid-item-first-col">
                            <Icon src={<FoodIcon/>} className="mr-1"/>{t('ChildSettings.SPECIAL_MEALS')}:
                        </div>
                        <div>
                            {
                                child.allergies && child.allergies.length > 0 && child.allergies[0].verified ?
                                    <Tag label={child.allergies[0].nutritionClass.name} type="small" className="dark"/>
                                    : <span className="text-muted">{t('Common.NO')}</span>
                            }
                        </div>
                    </div>

                    <div className="child-summary-grid-item">
                        <div className="grid-item-first-col">
                            <Icon src={<CashIcon/>} className="mr-1"/>{t('Welfare.TITLE')}:
                        </div>
                        <div>
                            {
                                child.childWelfare && hasVerifiedWelfare(child.childWelfare) ?
                                    <Tag label={t('TaskState.ACCEPTED')} type="small" className="success"/>
                                    : <span className="text-muted">{t('Common.NOT_ACTIVE')}</span>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                child.group?.institutionPlacesOrders &&
                <span className="text-muted">{t('Child.INSTITUTION_PLACES_ORDERS')}</span>
            }
        </div>
    </div>;
}

export default ChildSummaryCard;
